<template>
  <div>
    <el-row> 
      <el-col :span="24" class="infoTit">
        <span  v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==0"  class="tag bgRed titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==1"  class="tag bgGreen titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.otcType===0"  class="tag bgRed titColor radiusBox">Rx</span>
        <span  v-if="goodsinfo.newStatus==true||goodsinfo.newStatus=='true'"  class="tag bgGreen titColor">新品</span>
        <span  v-if="goodsinfo.medicalInsuranceType>0"  class="tag bgBlue titColor">医保</span>
        <span  v-if="goodsinfo.proprietary==1"   class="tag bgOrange titColor">自营</span>
         <span class="brandName">{{goodsinfo.brandName}} </span>
        <!-- {{ goodsinfo.name }} -->
         <span v-if="goodsinfo.alias==''||goodsinfo.alias==null">{{ goodsinfo.name }}</span>
         <span v-else>{{ goodsinfo.alias }}  </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg">
        <span>{{ goodsinfo.subTitle }}</span>
        <!--   <p v-for="(item, index) in goodsinfo.subTitle.split('；')" :key="index" >{{ item }}</p> -->
      </el-col>
    </el-row>
    <el-row class="infoBox">
      <el-col :span="6">
        <span class="infoKeyPrice">会员价：</span>
        <span class="price"><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":goodsinfo.price }}</span>
         <del>{{goodsinfo.price!=="***"?Number(goodsinfo.price)>=Number(goodsinfo.memberPrice)?'':goodsinfo.memberPrice:''}}</del>
      </el-col>
      <el-col :span="6">
        <span class="infoKey">推荐零售价：</span>￥{{
          goodsinfo.price==="***"?"会员可见":goodsinfo.originalPrice
        }}</el-col
      >
      <el-col :span="6"><span class="infoKey">毛利率：</span>{{ goodsinfo.grossProfitMargin }}%</el-col>
      <el-col :span="6"><span class="infoKey">净赚：</span>￥{{ goodsinfo.price==="***"?"会员可见":goodsinfo.earnings }}</el-col>
      <el-col :span="24" class="infoActivity"  v-for="(item,index) in promotionMap" :key="index">
        <span class="infoKey" v-if="index==0">促销活动:</span>
        <span class="infoKey" v-else></span>
        <span class="infoTag" v-if="item.type=='一元购'" @click="JumpONE_BUYFun(item.id)">{{item.type}}</span>
        <span class="infoTag" v-else-if="item.type=='限时拼团'"   @click="JumpFlashGroupFun(item.id)">{{item.type}}</span>
        <span class="infoTag" v-else-if="item.type=='限时抢购'"   @click="JumpFlashSaleFun(item.id)">{{item.type}}</span>
        <span class="infoTag" v-else>{{item.type}}</span>
        <span class="infoDes">{{item.name}}</span>
      </el-col>
      <el-col v-if="ByGoodsFlag" :span="24" class="infoActivity" @click.native="hotProductFun">
        <span class="hotProductTag">{{ByGoodsTag}} <b><img src="@/assets/hotProductTag.png" alt=""></b></span>
      </el-col>
      <!-- <el-col :span="24" class="infoActivity">
        <span class="infoKey"></span>
        <span class="infoTag">免运费</span>
        <span class="infoDes">该商品免运费</span>
      </el-col> -->
    </el-row>
    <el-row  :span="24"  class="CouponBox" v-if="goodsinfo.coupons.length > 0">
      <div>
        <span class="infoKey">优惠券:</span>
        <span class="conponMsg"  v-for="(item,index) in goodsinfo.coupons.slice(0,3)" :key="index" @click="drawer = true" type="primary" >{{item.couponName}}</span>
      </div>
    </el-row>
<el-drawer
  title="优惠券"
  :with-header="false"
  :visible.sync="drawer"   
  direction="rtl"
  :before-close="handleClose">
     <div class="headTit">优惠券</div>
     <div class="conMain">
       <div>
          <GetCouponItem v-for='(item,index) in goodsinfo.coupons' :key="index" :span="24" :couponItem="item"></GetCouponItem>
       </div>
     </div>
</el-drawer>


    <el-row  :span="24"  class="infoParameter">
      <div><span class="infoKey">商品编号:</span><span>{{goodsinfo.productSn }}</span></div>
       <div><span class="infoKey">国药准字:</span><span>{{goodsinfo.approvalNo}}</span></div>
      <div><span class="infoKey">药品规格:</span><span>{{goodsinfo.specification}}</span></div>
      <div><span class="infoKey">件装规格:</span><span>{{goodsinfo.partSpecification}}</span></div>
      <div><span class="infoKey">生产日期:</span><span>{{goodsinfo.producedDateFromat}}</span></div>
      <div><span class="infoKey">效期优于:</span><span>{{goodsinfo.validDateFromat}}</span></div>
      <div><span class="infoKey">医保类型:</span><span>{{goodsinfo.medicalInsuranceType===0?'非医保':goodsinfo.medicalInsuranceType===1?'甲类医保':'乙类医保'}}</span></div>
      <div>
        <span class="infoKey" style="text-indent: 30px;">库存:</span>
        <!-- <span>{{goodsinfo.quantity==-1 ||goodsinfo.quantity==0 ?'备货中':goodsinfo.quantity>1000?"充足":goodsinfo.quantity+goodsinfo.unit}}</span> -->
          <span v-if="goodsinfo.quantity==-1 || goodsinfo.quantity< Number(goodsinfo.minOrderNum)">备货中</span>
          <span v-if="goodsinfo.quantity>1000">充足</span>
          <span v-if="goodsinfo.quantity<=1000 && goodsinfo.quantity>=Number(goodsinfo.minOrderNum)">{{goodsinfo.quantity}}{{goodsinfo.unit}}</span>
      </div>
      <div v-if="goodsinfo.quantity<1 && goodsinfo.deliveryTimeFormat"><span class="infoKey">到货日期:</span><span>{{goodsinfo.deliveryTimeFormat}}</span></div>
      <div  @click="Download(goodsinfo.id)"> <span class="infoKey" >首营资料：</span> <strong class="downBtn">查看/下载</strong></div>
    </el-row>

    <el-row class="infoNumBox">
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="goodsinfo.minOrderNum"
          :max="Number(goodsinfo.quantity)"
          :step="goodsinfo.minOrderNum"
          step-strictly
        ></el-input-number>
      </el-col>
    </el-row>
    <div class="infoBtns">
      <div :span="24">
        <span class="infoKey"></span>
        <button :disabled="goodsinfo.quantity< Number(goodsinfo.minOrderNum)" :class="goodsinfo.quantity< Number(goodsinfo.minOrderNum)?'grey shopBtn':'shopBtn'"  @click="immediateBuy(goodsinfo)">
          立即购买
        </button>
        <button :disabled="goodsinfo.quantity< Number(goodsinfo.minOrderNum)" :class="goodsinfo.quantity< Number(goodsinfo.minOrderNum)?'grey addBtn':'addBtn'" @click="addCartFun(goodsinfo)">
          <!-- <span v-if="goodsinfo.quantity< Number(goodsinfo.minOrderNum)">补货中</span>
          <span v-else>加入购物车</span> -->
          加入购物车
        </button>
      </div>
    </div>
    <!-- <el-col :span="24">
      <el-col :span="4"
        ><div class="grid-content bg-purple">
          服务承诺：<i class="el-icon-circle-check" aria-hidden="true"></i></div
      ></el-col>
      <el-col :span="4"
        ><div class="grid-content bg-purple-light">
          正品保障<i class="el-icon-circle-check" aria-hidden="true"></i></div
      ></el-col>
      <el-col :span="8"
        ><div class="grid-content bg-purple">
          满1000免运费【新疆除外】<i
            class="el-icon-circle-check"
            aria-hidden="true"
          ></i></div
      ></el-col>
      <el-col :span="4"
        ><div class="grid-content bg-purple-light">药监认证</div></el-col
      >
    </el-col> -->



    <!-- 多店铺弹窗 -->
    <el-dialog
      id="StoreBox"
      title="勾选店铺"
      :visible.sync="StoreShow"
    >
     <el-col :span="24" class="selectedAllBox">
            <el-col :span="19">
              <label>
                <input
                  type="checkbox"
                  :checked="selectedAll"
                  @change="AllSelectedFun()"
                />
                全选
              </label>
            </el-col>
     </el-col>
     <div class="MembersBox">
        <el-col class="LineBox" :span="24" v-for="(item, index) in StoreList" :key="index" @click="jumpPath(index)">
          <el-col :span="19">
            <label  @change="handleCheckChange(item)">
              <input
                type="checkbox"
                :checked="item.flag"
              />
              {{item.enterpriseName}}
                <span class="lockBtn Btnbox" v-if="item.disableStatus==1">锁定</span>
                <span class="certifiedBtn Btnbox" v-else-if="item.auditStatus==4">正常</span>
                <span class="pastBtn Btnbox" v-else-if="item.auditStatus==6">资质过期</span>
                <span class="unauthorizedBtn Btnbox" v-else>未认证</span>
            </label>
          </el-col>
            <el-col :span="5" class="counterBox">
              <div class="counter">
                  <InputNumbers :item="goodsinfo" :StoreItem="item"></InputNumbers> 
              </div>
            </el-col>
        </el-col>
     </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="AddStoreFun(goodsinfo)">加入购物车</el-button>
      </span>
    </el-dialog>


  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.Btnbox{
  width:60px;
    text-align: center;
    height: 20px;
    padding: 0 5px;
    line-height: 19px;
    
    font-size: 12px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
}
.lockBtn{
  background-color: #999999;
    border-color: #999999;
    border-width: 1px;
    color: #000;
}
.pastBtn{
  background-color: #8f8e92;
    border-color: #8f8e92;
    border-width: 1px;
    color: #000;
}
.unauthorizedBtn{
  background-color: #ffd303;
    border-color: #ffd303;
    border-width: 1px;
    color: #000;
}
.counterBox{
  position: relative;
  .counter{
    position: absolute;
    top: -5px;
  }
}
#StoreBox{
  .selectedAllBox{
    padding-bottom:10px;
    margin-bottom: 6px;
    border-bottom: 1px dotted #ccc;
  }
  .MembersBox{
  max-height:400px;
  overflow-y: scroll;
  float: left;
  width: 100%;
}

::-webkit-scrollbar  
{  
    // width:3px;  
     width:8px;  
}
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    border-radius: 10px;  
    background-color: rgba(0,0,0,0.1); 
} 
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  
    background-color: rgba(0,0,0,0.1);
} 
  .LineBox{
    padding-bottom:10px;
    margin-bottom: 6px;
    border-bottom: 1px dotted #ccc;
    label{
      line-height: 20px;
      padding-top:5px;
    }
  }
  .el-dialog{
    background: url('../../assets/alertBg.png') no-repeat,#fff;
    background-size:100% auto;
    padding-top:70px;
    border-radius: 10px;
    .el-dialog__header{
        padding: 10px 20px 0px;
    }
    .el-dialog__body{
      padding:10px 20px;
    }
    .el-dialog__close{
      display: none;
    }
    .dialog-footer{
       .el-button{
            margin: 0 auto!important;
          display: block!important;
          background: #ff4800!important;
          border-radius: 30px!important;
          border: 1px solid #ff4800!important;
          padding: 10px 50px;
        }
      }
  }
  
}
.info {
  overflow: hidden;
  .infoTit {
    font-size: 20px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    .brandName{
      font-weight: bolder;
      color: #333;
    }
    .tag{
        width:42px;
        text-align: center;
          height: 20px;
          // padding: 0 5px;
          line-height: 19px;
          margin-top: 2px;
          border-width: 1px;
          font-size:14px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
        margin-right:3px;
          &.titColor{
            color:#fff
          }
           &.radiusBox{
            border-radius:50%;
          }
          &.bgRed{
             background-color: #DD1F1F;
              border-color: #DD1F1F;
          }
          &.bgBlue{
              background-color: #409eff;
              border-color: #409eff;
          }
          &.bgGreen{
            background-color:  #52C41A;
              border-color:  #52C41A;
          }
          &.bgOrange{
             background-color:#FF7A45;
               border-color: #FF7A45;
          }
    }
  }
  .infoMsg {
    color: #FFD303;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
        margin-top: 5px;
  }
  span.infoKey {
    width: 86px;
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
  span.infoKeyPrice{
     width:56px;
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
  .downBtn{
    cursor: pointer;
    &:hover{
         color: #FFD303;
    }
  }
  .infoNumBox {
    padding: 0px 10px;
    margin-bottom: 25px;
  }
  .infoBtns {
    padding: 0px 10px;
    z-index: 999;
    position: relative;
    margin-bottom: 20px;
    button {
      width: 150px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 0;
      padding: 0;
      &.addBtn {
        background: #FFD303;
        color: #000;
        margin-left: 20px;
        border: 1px solid #FFD303;
        outline: none;
      }
      &.shopBtn {
        background: #ff3333;
        color: #fff;
        border: 1px solid #ff3333;
        outline: none;
      }
      &.grey{
         background: #d4d2cf;
          color: #333;
          border: 1px solid #d4d2cf;
      }
    }
  }
  .CouponBox {
    padding: 0px 10px 5px;
    margin-bottom:10px;
    color: #FFD303;
    div{
      float: left;
      width:100%;
       .conponMsg{
         font-size: 14px;
         padding: 2px 5px;
         border: 1px solid #FFD303;
         border-radius: 4px;
         margin-right: 10px;
         font-weight: bold;
         &:hover{
            background: #f6f6f6;
            border: 2px solid #FFD303;
         }
       }
    }
  }

  // 重置优惠券组件样式，提高权重
  /deep/.coupon{
    width:300px;
  }
  /deep/.el-drawer{
    width: 340px!important;
    padding:0 20px 20px 20px;
  }
  .headTit{
       margin-top: 15px;
    line-height: 40px;
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  .conMain{
    max-height:830px;
        overflow-y: scroll;
    overflow-x: hidden;
  }
   ::-webkit-scrollbar {
    width: 2px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
  .infoParameter {
    padding: 0px 10px 25px;
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 20px;
    color: #333;
    div{
      float: left;
      width: 50%;
       margin-bottom: 15px;
        &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    // .el-col {
    //   margin-bottom: 15px;
     
    // }
  }
  .infoBox {
    background: #f6f6f6;
    padding: 20px 10px 0 10px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top:10px;
    del{
      padding-left:5px;
      font-size:12px;
        color: #333;
      text-decoration: line-through
    }
    .el-col {
      margin-bottom: 25px;
      &.infoActivity {
        margin-bottom: 12px;
        .hotProductTag{
          cursor: pointer;
          margin: 0;
          border-radius:20px;
          background: #FFD303;
          color: #000;
          padding: 5px 15px;
          font-size: 15px;
          text-align: center;
          b{ 
              display: inline-block;
              width: 15px;
              height: 15px;
              position: relative;
            img{
              display: inline-block;
              position: absolute;
              top: 2px;
              left: 0;
              width: 15px;
              height: 15px;
            }
           
          }
        }
      }
      &:last-child {
        margin-bottom:25px;
      }
    }

    span.price {
      color: #f43c38;
      font-size: 16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    span.infoTag {
      border: 1px solid #FFD303;
      color: #FFD303;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0 10px;
      cursor: pointer;
    }
    span.infoDes {
      color: #666;
    }
  }
}
</style>
<script>
const InputNumbers = () => import("components/common/InputNumbers.vue");
const GetCouponItem = ()=>import("components/common/GetCouponItem.vue");
import {AddCart,DownloadFirstTest,getByGoodsId} from "api/product.js"
import { mapState,mapMutations,mapGetters} from "vuex";
 import { GetMemberStore } from "api/member.js";
export default {
  name: "GoodsInfo",
  data() {
    return {
      num:0,
      promotionMap:[],
// 领取优惠券参数
       drawer: false,
      //  佣金热销标签显示
        ByGoodsFlag:false,
        ByGoodsTag:'',
        ByGoodsId:'',

          selectedAll:false,
          StoreShow:false,
          StoreList:[],
          checkedIds: [], // 初始选中的值
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
  },
  components: {
    GetCouponItem,
    InputNumbers
  },
  computed: {
    //计算属性可实时更新购物车数量
   CarGoodsNum(){
     return this.$store.getters.CarGoodsNum
   },
    ...mapState({
      goodsnum: state => state.carGoodsNum,
    })
  },
  methods: {
    // 跳转热销商品列表
    hotProductFun(){
      // alert(this.ByGoodsId)
       localStorage.setItem("ByGoodsTag", this.ByGoodsTag);
       localStorage.setItem("ByGoodsId", this.ByGoodsId);
       this.$router.push({name:'ProductType',query:{type:12,ByGoodsTag:this.ByGoodsId}})
    },
    JumpONE_BUYFun(promotionId){
        //  this.goodsinfo.promotionMap.promotionId 
      this.$router.push({
         name: "ONEBUYDetail",
        query: { productId:  this.goodsinfo.id, promotionId:promotionId },
      });
    },
    // 跳限时拼团详情页
    JumpFlashGroupFun(promotionId){
      this.$router.push({ name: "FlashGroupDetail", query: { promotionId:promotionId } });
    },
    // 跳限时抢购详情页
    JumpFlashSaleFun(promotionId){
       this.$router.push({
         name: "ActivityModuleDetail",
         query: { id: promotionId,ActivityType:2},
      });
    },
    handleClose(done) {
        done();
      },
     Download(goodsId){
        // 显示loading
        this.$loading({ fullscreen: true ,background:'#ffffff45'})
      //  alert(goodsId)
         DownloadFirstTest(goodsId).then(res=>{
           if(res.data.code==400){
               this.$message({
                    message:res.data.msg,
                    type: 'warning'
                  });
                // 关闭loading
                this.$loading().close();
           }else{
            let href=res.data.data;
              // 关闭loading
            this.$loading().close();
            window.open(href, '_blank', 'toolbar=yes, width=900, height=700')
           }
          
     
        })
    },
     ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
     ...mapGetters({
        typeQuantity:"typeQuantity"
    }),
    handleChange() {
      // value
    },

    AddStoreFun(data){

      if (this.checkedIds.length < 1) {
        this.$message({
          message: "最少勾选一个门店",
          type: "warning",
        });
        return false;
      }
        this.StoreShow = false
        //  多个店铺，加购物
        // 获取选中值。将商品添加到选中店铺
          const addcartDate = [];
          this.StoreList.forEach((item) => {
            if(item.flag){
                    addcartDate.push({
                      isRecentExpiration:data.isRecentExpiration,
                      quantity: item.num,
                      productId:data.id==-1?data.productId:data.id,
                      goodNum:data.goodNum,
                      memberStoreId: item.id
                    });
            }
          });
         console.log(addcartDate)
            // 加入购物车接口
            AddCart(addcartDate).then((data) => {
              // this.$router.push({ name: "Cart", query: { id: id } });
              if (data.data.code == 200) {
                // 提示信息
                this.$notify({
                  title: "加入购物车",
                  message: "成功",
                  type: "success",
                  duration: 1000,
                });
                this.setCarGoodsNum(this.num);
              } else {
                this.$notify({
                  title: "提示信息",
                  message: data.data.msg,
                  type: "error",
                  duration: 1000,
                });
              }
            });

          
    },
    // 全选
    AllSelectedFun() {
     this.selectedAll=!this.selectedAll
      let checkedIds = [];
      this.StoreList.forEach((item) => {
        checkedIds.push(item.id);
        item.flag = this.selectedAll ? true : false;
      });
      this.checkedIds = this.selectedAll ? checkedIds : [];

    },
    handleCheckChange(item){
      item.flag=!item.flag
       var bStop = true;
       for (var i = 0; i < this.StoreList.length; i++) {
          if (!this.StoreList[i].flag) {
            bStop = false;
            break;
          }
        }
        this.selectedAll=bStop
        let checkedIds = [];
        this.StoreList.forEach((item) => {
          if (item.flag) {
            checkedIds.push(item.id);
          }
        });
        this.checkedIds = checkedIds;
      console.log(this.checkedIds)

    },

   addCartFun(data) {
       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
      // {"isRecentExpiration":0,"quantity":10,"productId":1}


      // 如果一个店铺则直接加购物，多个店铺则跳出弹窗显示店铺
        GetMemberStore().then((res) => {
                if(res.data.data.length>1){
                      this.StoreShow=true
                      this.StoreList=res.data.data
                      this.StoreList.forEach((item) => {
                        item.flag = false;
                        item.num = this.goodsinfo.minOrderNum
                      });
                }else{
                  this.StoreShow=false
                  //  一个店铺，直接加购物
                  this.StoreList=res.data.data
                  const addcartDate=[];
                  addcartDate.push({
                    isRecentExpiration:data.isRecentExpiration,
                    quantity: this.num,
                    productId:data.id,
                    goodNum:data.goodNum,
                    memberStoreId:this.StoreList[0].id
                  });
                  // 加入购物车接口
                  AddCart(addcartDate).then((data) => {
                    // this.$router.push({ name: "Cart", query: { id: id } });
                    if (data.data.code == 200) {
                      // 提示信息
                      this.$notify({
                        title: "加入购物车",
                        message: "成功",
                        type: "success",
                        duration: 1000,
                      });
                      let count=this.num
                      this.setCarGoodsNum(count);
                    } else {
                      this.$notify({
                        title: "提示信息",
                        message: data.data.msg,
                        type: "error",
                        duration: 1000,
                      });
                    }
                  });
                }
                console.log('是否多店铺'+this.StoreShow)
            });
     
    },
    immediateBuy:function(data) {

       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'未认证用户不能购买',
              type:"warning"
          })
          return false
      }
      const addcartDate=[];
      addcartDate.push({
        isRecentExpiration:data.isRecentExpiration,
        quantity:this.num,
        productId:data.id,
        goodNum:data.goodNum,
      })
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });

            let count=this.num
            this.setCarGoodsNum(count);
            this.$router.push({ name: "Cart" });

        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    }
  },
  mounted() {
  },
  watch: {
    goodsinfo: function() {
    },
  },
  created() {
    this.num=this.goodsinfo.minOrderNum
    // this.promotionMap=this.goodsinfo.promotionMap
    let arr=[];
    this.goodsinfo.promotionMap.forEach(item => {
       if(item.type!='ONE_BUY促销活动'){
         arr.push(item)
       }
    });
    this.promotionMap=arr
    // 传入goodsId获取是否设置 佣金热销标签
    // console.log('aaaa',this.goodsinfo)
    getByGoodsId(this.goodsinfo.id).then(res=>{
      // alert(789)
      console.log(res,"123456")
      if (res.status === 400) {
        this.ByGoodsFlag=false
      }else{
        this.ByGoodsFlag=true
        this.ByGoodsTag=res.data.data.categroyName
        this.ByGoodsId=res.data.data.categroyId
      }
    })
  },
};
</script>
